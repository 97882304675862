<template>
  <Layout>
    <div class="row mb-5">
      <div class="col-md-6 offset-lg-3 pb-4">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="registration-form">
              <p class="welcome mb-4">{{ $t("welcome") }}</p>
              <div style="padding-left: 6%; padding-right: 6%">
                <form class="needs-validation" @submit.prevent="tryToRegisterIn">
                  <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Registration
                    successfully.
                  </b-alert>
                  <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">{{
                      notification.message
                    }}
                  </div>

                  <div>
                    <label for="email" class="form-label">{{ $t("email") }}</label>
                    <div class="form-icon right">
                      <input type="email" class="form-control form-control-icon" id="email"
                             v-model="user.email"
                             :class="{'is-invalid': submitted && v$.user.email.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-mail-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="red-color">
                      <span v-show="this.isUpperCase">Email must be lowercase.</span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label for="firstname" class="form-label">{{ $t("firstname") }}</label>
                    <div class="form-icon right">
                      <input type="text" class="form-control form-control-icon" id="firstname"
                             v-model="user.firstname"
                             :class="{'is-invalid': submitted && v$.user.firstname.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-user-3-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.firstname.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>


                  <div class="mt-4">
                    <label for="lastname" class="form-label">{{ $t("lastname") }}</label>
                    <div class="form-icon right">
                      <input type="text" class="form-control form-control-icon" id="lastname"
                             v-model="user.lastname"
                             :class="{'is-invalid': submitted && v$.user.lastname.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-user-3-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.lastname.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label for="phone_number" class="form-label">{{ $t("phone_number") }}</label>
                    <div class="form-icon right">
                      <input type="number" class="form-control form-control-icon" id="phone_number"
                             v-model="user.phone_number"
                             :class="{'is-invalid': submitted && v$.user.phone_number.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-phone-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.phone_number.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="form-label">{{ $t("personal_address") }}</label>
                    <div class="form-icon right">
                      <VueGoogleAutocomplete id="personal_address" classname="form-control form-control-icon"
                                             :class="{'is-invalid': submitted && v$.user.personal_address.$error,}"
                                             :placeholder="$t('type_in')"
                                             v-on:placechanged="getAddressData">
                      </VueGoogleAutocomplete>
                      <i class="ri-home-2-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.personal_address.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>


                  <div class="mt-4" v-if="this.isSeenPassword">
                    <label for="password_eye" class="form-label">{{ $t("password") }}</label>
                    <div class="form-icon right">
                      <input v-model="user.password" type="text" class="form-control form-control-icon"
                             id="password_eye"
                             :class="{'is-invalid': submitted && v$.user.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_close_eye" class="form-label">{{ $t("password") }}</label>
                    <div class="form-icon right">
                      <input v-model="user.password" v-if="!this.isSeenPassword" type="password"
                             class="form-control form-control-icon" id="password_close_eye"
                             :class="{'is-invalid': submitted && v$.user.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange"
                         class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>


                  <div class="mt-4" v-if="this.isSeenPassword">
                    <label for="password_confirm_eye" class="form-label">{{ $t("confirm_password") }}</label>
                    <div class="form-icon right">
                      <input v-model="user.confirm_password" type="text" class="form-control form-control-icon"
                             id="password_confirm_eye"
                             :class="{'is-invalid': submitted && v$.user.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_confirm_close_eye" class="form-label">{{ $t("confirm_password") }}</label>
                    <div class="form-icon right">
                      <input v-model="user.confirm_password" v-if="!this.isSeenPassword" type="password"
                             class="form-control form-control-icon" id="password_confirm_close_eye"
                             :class="{'is-invalid': submitted && v$.user.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange"
                         class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-3 d-grid gap-2">
                    <button class="btn submit" type="submit">{{ $t("sign_up") }}</button>
                  </div>
                </form>

                <div class="d-flex justify-content-between link-sign-in text-center mt-3">
                  <RouterLink to="/login">
                    <span class="existing-user text-black">{{ $t("existing_user") }}</span>
                    <span class="red-color">&nbsp;{{ $t("sign_in") }}</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px"></div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Constants from "../../../utils/constants";

import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "../../../../app.config.json";


import {
  authMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Signup",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  name: "signup",
  components: {
    Layout,
    VueGoogleAutocomplete
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      isSeenPassword: false,
      user: {
        email: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        personal_address: "",
        password: "",
        confirm_password: "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations() {
    return {
      user: {
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
        firstname: {
          required: helpers.withMessage("First name is required", required),
        },
        lastname: {
          required: helpers.withMessage("Last name is required", required),
        },
        phone_number: {
          required: helpers.withMessage("Phone number is required", required),
        },
        personal_address: {
          required: helpers.withMessage("Personal address is required", required),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
        confirm_password: {
          required: helpers.withMessage("Confirm Password is required", required),
        },
      },
    }
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "horizontal");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isUpperCase(){
      return !Constants.EMAIL_PATTERN.test(this.user.email);
    }
  },
  methods: {
    ...authMethods,
    OnClickedPasswordVisibilityChange() {
      this.isSeenPassword = !this.isSeenPassword;
    },
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) return;
      if (this.isUpperCase) return;

      this.tryingToRegister = true;
      // Reset the regError if it existed.
      this.regError = null;
      this.isRegisterError = false;

      this.$isLoading(true); // show loading screen
      await this.register(this.user)
          .then((response) => {
            const {status, message, user} = response;
            if (status === "success") {
              this.tryingToRegister = false;
              this.isRegisterError = false;
              this.registerSuccess = true;
              localStorage.setItem('jwt', user.jwt)
              this.$router.push({
                path: "/confirmation",
              });
              // if (this.registerSuccess) {
              //   this.$router.push(
              //       this.$route.query.redirectFrom || {
              //         name: "home",
              //       }
              //   );
              // }
            } else {
              this.regError = message;
              this.tryingToRegister = false;
              this.isRegisterError = true;
            }
          })
          .catch((error) => {
            this.tryingToRegister = false;
            this.regError = error ? error : "";
            this.isRegisterError = true;
          })
          .finally(() => {
            this.$isLoading(false);
            this.submitted = false;
          })

    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      console.log(placeResultData, id);
      const com = document.querySelector('#personal_address');
      this.user.personal_address = com.value;
    },
  }
}
</script>

<style scoped>

</style>
